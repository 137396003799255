import React from "react"

import styles from "../../styles/main.module.css"

export default function TimeToBuild() {

  return (
    <React.Fragment>
      <h2>Can't Just Be a Theoretician</h2>
      <hr className={styles.top_seperator} />
      <p>Marc Andressen made a cool post a few months ago, when the pandemic started (and we ran out of toiletpaper... and medicine, and masks) basically saying that as a nation we've gotten kinda lazy and aren't building things. We focus on efficiency and globalization (at least, outside of silicon valley-style tech, though not sure if that's as "innovative" as it used to be... it seems really limited to internet), but that's really not the play (note how problems with the supply chain due to the black swan of the epidemic done fucked everything up for a while). Great idea, it's really shitty being dependent on China, for example, for all your medicine. Not only can you get played, but you can get just flat out unlucky. Also, it ain't a great look ngl (plus I wanna see cool shit built HERE by our LADS... nevermind the benefits that would have to stabilize the growing inequality we got here).</p>
      <p>Anyways, I've kind of bamboozled myself into thinking about things wrong you know. For some reason when I went to college I saw the career fair and I was like wow bro, I need to be a QUANT because QUANTS get BUCKS and being a quant MEANS that you are GOOD (anything else is for noobies and non-genius mathematicians or coders). Trading is ass bro. Who cares about stock prices. I wanna see mars landings. That's much better. As a general heuristic, I wanna build and help build coolerino shiterino, not F I N A N C E with the "financial engineering" that got us to 2008 (and worse in the future because fragilistas keep proliferating... dumbasses). Anyways, I've told myself for kind of a while that I need to "catch up" my math or whatnot because I gotta be like those nexties or something (idk). I also thought I had to do RESEARCH, because RESEARCH meant that you were GOOD (and otherwise you didn't "contribute" to society bada bing bada boom in an "intellectual" way... you must WRITE PAPERS blah blah blah). Anyways, WRONG. Also, SAD. This is clearly the wrong way to view things. I've deluded myself into doing the things that a) I never did b) I'm bad at. HUH what?! Not smart, not smart. Just because full-stack software engineering shit for plumbing companies (Amazon) is horrendous and SUCKS, doesn't mean you go do dumb shit like finance.</p>
      <p>Anyways, recently I've gotten back into ML. A little. I did some dumb coursera course in like a week or two and read like a paper or two, who knows. Anyways. I remember when I was "interested in ML" and I was going to those reading groups blah blah blah. Look, these are great ideas, but let me say something. I REMEMBER VERY LITTLE! I remember very little from those days! And they were just two years ago! Huh?! How is this fucking possible. Let me tell you assholes. I built basically nothing. I did fill in the blank classes and then stopped after my first grad class becaue I thought it was like idk "I'll recommence later." Well listen up cunts, you gotta be CONSISTENT. This doesn't mean you work 9-5 every day like the tantalized golden cage bitch boy that I imagine I'll become if I continue down this big tech but I wanna actually be a quant for citadel path (ugh yuck), but it means you stick to the interesting shit. That means, you gotta pick interesting shit that you are also passionate about, not whatever is "right" as I told myself (yea you gotta do what is the "right" thing to do like work for Jane Street and do ML blah blah blah and "research").  No, no, no. I gotta do what I wanna do, and to be able to do it well I gotta, surprise! build things. I can't just read the ML papers. I need to make ML projects and/or services idk what to learn this properly.</p>
      <p>And I can't do philosophy that isn't basically math. You see, math isn't really theory, it's practice. Solving problems is the shit. But in philosophy not only can I not tell apart who's got a dildo up their ass from who actually is an ass and a dildo but I also need to deal with, basically, debaters. Ugh! The problem is I don't really care about the argument, I just care about if I do it does it work. If I think it, do I understand something I didn't before. But here we are arguing about what the definition of religion should be. I'm not good in these sort of situations and I cannot stand them. Some people thrive but I do not, so I SHOULD NOT EMBARK ON A PATH OF HIGH EXPOSURE TO THEM NO SHIT SHERLOC. So why have I been setting myself up for failure by reading so much, doing so little, and planning on getting into Buddhism by READING, and philosophy by going to a group about ethics that doesn't ever discuss what we should do in our lives, but instead the CANON. CANON SUCKS. (Except of course, sometimes it's good). The problem is I wanna a) optionality in my reading b) not be told or led to the answer, I wanna make up an answer (and get help via experience you know). Maybe that's why I couldn't do the reading group, or maybe I was just depresso low self estimo idk.</p>
      <p>Anyways, anyways, this time for real I'm done. I'm just gonna a) build stuff that I wanna build b) read books that I wanna read and NOT read for some grand goal i.e. learning astronomy because it's something I wanted to do a DECADE ago and I'm not happy with my path blah blah blah c) reading gt youtube, but also reading ltlt doing you know, so I need to do more. This means Imma implement that YOLO algorithm I wanted to implement, I'll build the Poker game, I'll only take classes that are not pure theory (because I forget theory and I'll keep telling myself I don't understand the subject because I KNOW that if I cannot build it I don't understand it, but I won't have confidence in my ability to build it if I don't build at least some of it you see). Also the compiler. Also I wanna do something with hardware... what about some baby version of SLAM? Other forms of hardware automation with ML? Can also be software... what about libraries for cool tasks or a sort of self-replicating program. What about game bots. Hella cool opportunities out there. Why have I been deluding myself into BS gotta do "the right thing" or be "high status" or whatever other nonsense (ok we get it you jacked off a bit, that skewed your perception... also being at MIT you get to see all the winners and not really the losers, so you feel like you are the loser by process of elimination UGH).</p>
      <p>Also some computer graphics shit? That would be helpful to understand you know. And low level stuff. I like low level. Probably embedded systems of some form would be good. But also space. I wanna get into space you know. But I can't say to hell with it I'm not course 6 and want to learn E&M again because that will help (yea right). Fucking hell dude, just build applicable projects, robotics, etc. Then go work at such a startup/company or club etc. Also quantum mechanics? Fuck it, I won't be doing it at MIT. It's too late. I need to focus on the classics and GET GUD BRO. I'll learn to be an autodidact. Fuck classes. Fuck needing classes. Fuck lecture and blah blah blah. I wanna be able to sit down and read a quantum mechanics textbook and teach myself from the internet everything else. If I can do that (and combine it with social shit like cool talks to I'm not lonely) that I'm golden. Golden for life.</p>  
      <p>PS: Go wallstreetbets go! Imma buy a little GME when the market opens hell yea. Do it for the SYMBOLISM. It's about what it MEANS not about the profit (if Melvin goes under that'd be hilarious).</p>
      <div className={styles.phone_home}>
        <a href="/">Click me to return home.</a>
      </div>
    </React.Fragment>
  )
}